import React, { useEffect, useState } from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import EmailSoftwareSelectionForm from "../../organisms/EmailSoftwareSelectionForm";
import Guide from "../../templates/Guide";
import FormField from "../../molecules/FormField";
import OverflowContainer from "../../atoms/OverflowContainer";
import { Form, Row } from "antd";
import Button from "../../atoms/Button";
import { useAuth } from "../../../provider/authProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../../config/config.js";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const allGuideData = {
  Sendlane: {
    title: "Generate Access Token:",
    showStepNumbers: true,
    guideSections: [
      {
        text: "Go to Settings -> API on your Sendlane account",
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide1.png",
      },
      {
        text: "Click on + API Token",
      },
      {
        text: "Type Beena as the token name and click create",
      },
      {
        text: "You will see the following message telling you the access token has been created. Click on Copy API Token.",
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide2.png",
      },
      {
        text: "Paste that down here:",
      },
    ],
    esp: "Sendlane",
  },
  // TODO: Update the guide for Mailchimp with pictures and updates text/flow
  Mailchimp: {
    title: "Generate Access Token:",
    showStepNumbers: true,
    guideSections: [
      {
        text: "Go to Profile by clicking on the account icon on the top right of your Mailchimp dashboard",
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide1.png",
      },
      {
        text: "Click on Extras -> API Key",
      },
      {
        text: 'Click on "Create API Key" under our API Keys section',
      },
      {
        text: 'Type Beena as the API Key name and click on "Generate Key"',
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide2.png",
      },
      {
        text: 'You will see the API Key on the screen. Click on "Copy to clipboard"',
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide2.png",
      },
      {
        text: "Paste that down here:",
      },
      {
        text: "We also need the Audience ID to tag customers. Click on Audience -> All Contacts -> Settings -> Audience name and defaults.",
      },
      {
        text: "You will see the Audience ID on the screen.",
      },

      {
        text: "Copy and paste that down here as well:",
      },
    ],
    esp: "Mailchimp",
  },
};
const Step2 = () => {
  const query = useQuery();
  const { backendAxios, token } = useAuth();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userInfo);
  const [selectedEmailMarketingSoftware, setSelectedEmailMarketingSoftware] =
    useState(null);

  const [guideData, setGuideData] = useState(null);
  const handleTokenSubmission = (values) => {
    if (user.email === config.testEmail) {
      navigate("/onboarding/step3");
    } else {
      const allPromises = [];
      allPromises.push(
        backendAxios.post("apikeys", {
          apiKey: values.accessToken || null,
          apiKeyType: selectedEmailMarketingSoftware["name"],
          shop: query.get("shop"),
        })
      );

      if (selectedEmailMarketingSoftware["name"] === "Mailchimp") {
        allPromises.push(
          backendAxios.get("store/find?shop=" + query.get("shop"))
        );
      }
      Promise.all(allPromises).then(async (res) => {
        let shopResponse = res[1];
        await backendAxios.put(`store/${shopResponse.data._id}`, {
          audienceId: values.audienceId || null,
        });
        navigate("/onboarding/step3");
      });
    }
  };
  useEffect(() => {
    if (selectedEmailMarketingSoftware) {
      setGuideData(allGuideData[selectedEmailMarketingSoftware["name"]]);
    }
  }, [selectedEmailMarketingSoftware]);

  useEffect(() => {
    if (
      token &&
      backendAxios.defaults.headers.common["Authorization"] &&
      query.get("update") === "true" &&
      query.get("storeId")
    ) {
      backendAxios.put(`store/${query.get("storeId")}`, {});
      query.delete("update");
      query.delete("storeId");
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("update");
      newUrl.searchParams.delete("storeId");
      window.history.replaceState(null, "", newUrl.toString());
    } // eslint-disable-next-line
  }, [query]);

  return (
    <OnboardingTemplate
      leftCol={
        <EmailSoftwareSelectionForm
          setSelectedEmailMarketingSoftware={setSelectedEmailMarketingSoftware}
          allGuideData={allGuideData}
        />
      }
      rightCol={
        <OverflowContainer
          affixChildren={
            guideData && (
              <Form
                style={{ display: "flex", flexDirection: "column" }}
                onFinish={handleTokenSubmission}
              >
                <FormField
                  name="accessToken"
                  placeholder="Paste your access token here"
                  style={{ zIndex: 1000 }}
                  itemProps={{
                    style: {
                      width: "100%",
                      marginBottom: "0px",
                    },
                  }}
                />
                {guideData.esp === "Mailchimp" && (
                  <FormField
                    name="audienceId"
                    placeholder="Paste your audience ID here"
                    style={{ zIndex: 1000 }}
                    itemProps={{
                      style: {
                        width: "100%",
                        marginBottom: "0px",
                      },
                    }}
                  />
                )}
                <Row>
                  <Button
                    size="large"
                    type="secondary"
                    onClick={() => navigate("/onboarding/step3")}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #000",
                      color: "#000",
                    }}
                  >
                    Skip for now
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{
                      borderRadius: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    Submit
                  </Button>
                </Row>
              </Form>
            )
          }
        >
          <Guide guideData={guideData} />
        </OverflowContainer>
      }
    />
  );
};

export default Step2;
