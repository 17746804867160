import React from "react";
import { Row, Col } from "antd";
import PlatformCard from "../molecules/PlatformCard";
import Button from "../atoms/Button";
import OnboardingStep from "../templates/OnboardingStep";
import Title from "antd/es/typography/Title";

const emailMarketingSoftwares = [
  {
    name: "Sendlane",
    imgSrc: "/webapp/loggedInPages/onboarding/step2/sendlane.png",
  },
  {
    name: "Omnisend",
    imgSrc: "/webapp/loggedInPages/onboarding/step2/omnisend.png",
  },
  {
    name: "Mailchimp",
    imgSrc: "/webapp/loggedInPages/onboarding/step2/mailchimp.png",
  },
  {
    name: "ActiveCampaign",
    imgSrc: "/webapp/loggedInPages/onboarding/step2/activecampaign.png",
  },
  {
    name: "MailerLite",
    imgSrc: "/webapp/loggedInPages/onboarding/step2/mailerlite.png",
  },
  {
    name: "MailMunch",
    imgSrc: "/webapp/loggedInPages/onboarding/step2/mailmunch.png",
  },
  {
    name: "Other",
    imgSrc: "",
  },
];

const EmailSoftwareSelectionForm = ({
  setSelectedEmailMarketingSoftware,
  allGuideData,
}) => {
  const handleEmailMarketingSoftwareClick = (emailMarketingSoftware) => {
    setSelectedEmailMarketingSoftware(emailMarketingSoftware);
  };
  return (
    <OnboardingStep
      title="Finishing up!"
      percent={100}
      subtitle="What's your email marketing software?"
      description="You wil need to copy and paste API key from your email marketing software in the next step:"
      // footer={
      //   <div
      //     style={{
      //       display: "flex",
      //       flexDirection: "row",
      //       justifyContent: "flex-end",
      //       marginTop: "20px",
      //     }}
      //   >
      //     <Button
      //       type="primary"
      //       style={{
      //         height: "50px",
      //         color: "#000",
      //         backgroundColor: "rgba(0,0,0,0)",
      //         border: "1px solid #000",
      //         boxShadow: "0",
      //       }}
      //     >
      //       Other
      //     </Button>
      //   </div>
      // }
    >
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        {emailMarketingSoftwares
          .filter((x) => Object.keys(allGuideData).includes(x.name))
          .map((emailMarketingSoftware) => (
            <Col span={8} key={emailMarketingSoftware.name}>
              <PlatformCard
                imgSrc={emailMarketingSoftware.imgSrc}
                name={emailMarketingSoftware.name}
                onClick={() =>
                  handleEmailMarketingSoftwareClick(emailMarketingSoftware)
                }
                disabled={false}
              />
            </Col>
          ))}
      </Row>
      <Title level={5}>Coming Soon</Title>
      <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
        {emailMarketingSoftwares
          .filter((x) => !Object.keys(allGuideData).includes(x.name))
          .map((emailMarketingSoftware) => (
            <Col span={8} key={emailMarketingSoftware.name}>
              <PlatformCard
                imgSrc={emailMarketingSoftware.imgSrc}
                name={emailMarketingSoftware.name}
                onClick={() =>
                  handleEmailMarketingSoftwareClick(emailMarketingSoftware)
                }
                disabled={true}
              />
            </Col>
          ))}
      </Row>
    </OnboardingStep>
  );
};

export default EmailSoftwareSelectionForm;
