import React from "react";

import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import Guide from "../../templates/Guide";
import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const allGuideData = {
  Sendlane: {
    title: "Accurate Replenishment Reminder",
    subtitle:
      "Send accurate timely replenishment reminder emails. This date is specific to each customer.",
    guideSpan: 24,
    showStepNumbers: true,
    guideSections: [
      {
        text: "Edit your replenishment reminder automation.",
      },
      {
        text: "Remove old triggers and add a new one -> Tag Added -> Once Per Day -> NextPurchaseReminder.",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/replenishment1.png",
      },
      {
        text: "You are all set! This automation will keep sending replenishment reminder emails to your customers once they get closer to their predicted purchase date. If you have phone number of the customer, replenishment reminders are great to send via SMS.",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/replenishment2.png",
      },
    ],
  },
  Mailchimp: {
    title: "Accurate Replenishment Reminder",
    subtitle:
      "Send accurate timely replenishment reminder emails. This date is specific to each customer.",
    guideSpan: 24,
    showStepNumbers: true,
    guideSections: [
      {
        text: "Edit your replenishment reminder automation.",
      },
      {
        text: "Remove old triggers and add a new one -> Contact tagged -> NextPurchaseReminder.",
        imgSrc: "/webapp/loggedInPages/useCases/mailchimp/replenishment1.png",
      },
      {
        text: "You are all set! This automation will keep sending replenishment reminder emails to your customers once they get closer to their predicted purchase date. If you have phone number of the customer, replenishment reminders are great to send via SMS.",
        imgSrc: "/webapp/loggedInPages/useCases/mailchimp/replenishment2.png",
      },
    ],
  },
};
const UseCaseReplenishment = () => {
  const user = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  return (
    <LoggedInPagesTemplate>
      <Row>
        <Col span={24} xxl={16}>
          <Guide guideData={allGuideData[user.store.apiKeyObject.apiKeyType]} />
        </Col>

        <Col span={24} xxl={16} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            style={{ height: "50px", borderRadius: "4px" }}
            onClick={() => {
              navigate("/use-cases/churn");
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </LoggedInPagesTemplate>
  );
};

export default UseCaseReplenishment;
